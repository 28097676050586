import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSession } from '../../App';
import Spinner from '../../components/Spinner';
import firebase from "../../components/Firebase";
import NewListButton from "../../components/NewListButton";
import SharedIcon from '../../components/SharedIcon';
import MainMenu from '../../components/MainMenu';
import {LIST} from '../../constants/routes';

import './ListsScreen.scss';

function ListsScreen() {
  const [isLoading, setIsLoading] = useState(true);
  const [lists, setLists] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const user = useSession();

  useEffect(() => {
    var unsubscribe = null;

    try {
      unsubscribe = firebase.getMyLists().onSnapshot(querySnapshot => {
        const readList = [];

        querySnapshot.forEach(doc => {
          const list = doc.data();
          const shared = list.shared_with && list.shared_with.length > 1;

          readList.push({ id: doc.id, name: list.name, shared_with: list.shared_with, shared: shared });
        });

        setIsLoading(false);
        setLists(readList);
      })
    } catch (e) {
      setIsLoading(false);
      setError(true)
      setErrorMessage('Error getting lists' + e);
    }

    const savedListIds = JSON.parse(localStorage.getItem('list_ids'));
    if (savedListIds) {
      savedListIds.forEach((id) => {

        // Ignore all stored list Ids that does not have the correct format.
        if(/^[0-9a-zA-Z]{8}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{4}-[0-9a-zA-Z]{12}$/.test(id)) {
          var docRef = firebase.getList(id);
          docRef.get().then(doc => {
            if (doc.exists) {
              var shared_with = doc.data().shared_with;

              if (!shared_with.includes(user.uid)) {
                shared_with.push(user.uid);
                docRef.set({ shared_with: shared_with }, { merge: true });
              }
            }
          });
        }
      })
      localStorage.removeItem('list_ids');
    }

    return function cleanup() {
      unsubscribe && unsubscribe();
    };
  }, [user.uid]);

  if (isLoading) {
    return (
      <Spinner />
    );
  }

  if (error) {
    return (
      <div>
        {errorMessage}
      </div>
    );
  }

  if (lists.length === 0) {
    return (<>
      <MainMenu/>

      <div className="main-content-with-menu">
        <div className="my-lists-container">
          <span className="content">Oh, such emptiness <span role="img" aria-label="ghost-emoji">👻</span> I guess you haven't made any lists yet then. No pressure, but this is a really good time to do it.</span>
          <div className="new-list-container"><NewListButton /></div>
        </div>
      </div>
      </>
    )
  }

  return (<>
    <MainMenu/>

    <div className="main-content-with-menu">
      <div className="my-lists-container">
        <ul>
          {lists.map((list, index) => (
            <li key={index}>
              <Link to={LIST.replace(':id', list.id)}>{list.name}
                <div className="float-right">
                  <SharedIcon shared={list.shared} />
                </div>
              </Link>
            </li>
          ))}
        </ul>
        <div className="new-list-container"><NewListButton /></div>
      </div>
    </div>
    </>
  );
}

export default ListsScreen;