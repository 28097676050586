import React, {useState, useRef} from 'react';
import './NewItem.scss';

function NewItem(props) {
  const [value, setValue] = useState('');
  const inputRef = useRef();

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleCreate(e);
    }
  };

  const handleCreate = (e) => {

    // To get autoCapitalization=sentances to work
    if (e.key !== 'Enter') {
      e.preventDefault();
    }

    if (value !== '') {
      props.onSave(value);
      setValue('');

      if(inputRef && inputRef.current){
        inputRef.current.focus();
      }
    }
  };

  return (
    <div className="create-item-conteiner">
      <input type="text" placeholder="Name of new item to add to list" value={value} onChange={e => setValue(e.target.value)} onKeyPress={handleKeyPress} ref={inputRef} autoCapitalize='sentences'/>

      <a type="button" href="/" onClick={handleCreate}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"/></svg>
      </a>
    </div>
  );
}

export default NewItem;