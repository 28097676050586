import React, { useState, useEffect } from 'react';
import './Spinner.css';

function Spinner() {
  const [showSpinnerYet, setShowSpinnerYet] = useState(false);

  const timer = setTimeout(() => setShowSpinnerYet(true), 1000);

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    showSpinnerYet ? <div className="spinner">Loading ...</div> : null
  );
}

export default Spinner;