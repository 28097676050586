import React from 'react'
import firebase from '../../components/Firebase'
import { useToasts } from 'react-toast-notifications'
import DeleteButton from '../../components/DeleteButton';
import { useSession } from '../../App';
import { useNavigate } from 'react-router-dom';

function DeleteList(props) {
	const { addToast } = useToasts();
	const user = useSession();
	const navigate = useNavigate();
	
	const deleteHandler = () => {
		if (props.owner === user.uid) {
			firebase.deleteList(props.id).then(() => {
				addToast("And just like that the list is gone. Hope you're happy now.", { appearance: 'success', autoDismiss: true });
				navigate('/lists');
			}).catch(function () {
				addToast("Unable to delete the list. Please try again a later.", { appearance: 'error', autoDismiss: true });
			});
		}
	};
	
	return (<>
		<h2>Delete this list</h2>
		<p>To delete this list press and hold the delete button below. Deleting a list cannot be undone.</p><br/>
		<DeleteButton buttonText="Delete list" holdButtonText="Hold to delete" deleteHandler={deleteHandler} />
    </ >);
}

export default DeleteList