import React from 'react';
import './Footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <p>© 2022 Listy.no. All rights reserved. Icons by <a href="https://fontawesome.com/license">FontAwsome</a></p>
    </footer>
  );
}

export default Footer;