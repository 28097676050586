import React, {useState} from 'react';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Menu, Item as MenuItem, useContextMenu } from 'react-contexify';

import "react-contexify/dist/ReactContexify.css";
import './Item.scss';


function Item(props) {
  const [value, setValue] = useState(props.value);
  const [editMode, setEditMode] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition, } = useSortable({ id: props.id });
  const style = { transition, transform: CSS.Translate.toString(transform) };
  const { show } = useContextMenu({ id: props.id });

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      saveItem();
    }
  };

  const saveItem = () => {

    if(value && value !== '') {
      props.onSave(value);
    } else {
       // revert back to original value if empty
       setValue(props.value);
    }  

    setEditMode(false);
  }

  if (editMode) {
    return (
      <div className="input-container">
      <input autoFocus type="text"
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyUp={handleKeyUp}
        onBlur={saveItem}
        className="item-input-field" />
      </div>
    );
  }

  return (
    <li ref={setNodeRef} style={style} className="shopping-list-item">

      {!props.done &&
        <button className="dnd" {...listeners} {...attributes} onClick={show}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M496 288H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm0-128H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16z"/></svg>  
        </button>
      }

      <span className={(props.done ? 'done' : '') + ' item'} onClick={props.onDone}>{props.value}</span>

      {!props.done &&
      <button className="actions" onClick={show}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M96 184c39.8 0 72 32.2 72 72s-32.2 72-72 72-72-32.2-72-72 32.2-72 72-72zM24 80c0 39.8 32.2 72 72 72s72-32.2 72-72S135.8 8 96 8 24 40.2 24 80zm0 352c0 39.8 32.2 72 72 72s72-32.2 72-72-32.2-72-72-72-72 32.2-72 72z"/></svg>  
        </button>
      }

      <Menu id={props.id}>
        <MenuItem onClick={() => setEditMode(true)}>
          Edit
        </MenuItem>
        <MenuItem onClick={props.onDelete}>
          Delete
        </MenuItem>
      </Menu> 
    </li>
  );
}

export default Item;