import React, {useState, useEffect, useRef} from 'react';
import './DeleteButton.scss';

function DeleteButton(props) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [stopDeleting, setStopDeleting] = useState(false);

  const buttonRef = useRef();

  useEffect(() => {
    let tempButtonRef = buttonRef.current;

    buttonRef.current.addEventListener("touchstart",startDelete,{ passive: false });
    buttonRef.current.addEventListener("touchmove",startDelete,{ passive: false });
    return () => {
      tempButtonRef.removeEventListener("touchstart", startDelete);
      tempButtonRef.removeEventListener("touchmove", startDelete);
    };
  });

  const startDelete = (e) => {
    e.preventDefault();

    if(!isDeleting){
      setStopDeleting(setTimeout(() => props.deleteHandler(), 1000));
      setIsDeleting(true);
    }
  }

  const cancelDelete = (e) => {
    e.preventDefault();

    if(isDeleting) {
      clearTimeout(stopDeleting);
      setIsDeleting(false);
    }
  }

  return (
    <button
      ref={buttonRef}
      className={"delete " + (isDeleting ? ' deleting' : '')}
      onClick={(e) => {e.preventDefault();}}
      onTouchCancel={(e) => {e.preventDefault();}}
      onMouseDown={startDelete}
      onTouchEnd={cancelDelete}
      onMouseUp={cancelDelete}
      onMouseLeave={cancelDelete}>{(isDeleting ? props.holdButtonText : props.buttonText)}</button>
  );
}

export default DeleteButton;