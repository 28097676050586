import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

const config = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID
};

class FirebaseAPI {
	constructor() {
		firebase.initializeApp(config);
		this.auth = firebase.auth();
		this.db = firebase.firestore();
	}

	verifyEmail(oneTimeCode) {
		return this.auth.applyActionCode(oneTimeCode);
	}

	resetPassword(email) {
		return this.auth.sendPasswordResetEmail(email);
	}

	login(email, password) {
		return this.auth.signInWithEmailAndPassword(email, password);
	}

	logout() {
		return this.auth.signOut();
	}

	verifyPasswordResetCode(oneTimeCode){
		return this.auth.verifyPasswordResetCode(oneTimeCode);
	}

	confirmPasswordReset(oneTimeCode, password) {
		return this.auth.confirmPasswordReset(oneTimeCode, password);
	}

	createCredential(email, password) {
		return this.auth.EmailAuthProvider.credential(email, password);
	}

	sendVerificationEmail(){
		return this.auth.currentUser.sendEmailVerification();
	}

	async register(name, phoneNumber, email, password) {

		// Robots will fill in the phoneNumber and user will not be created			
		if(phoneNumber !== '') {
			return new Promise((resolve) => {
				return resolve('User created!');
			});
		}

		await this.auth.createUserWithEmailAndPassword(email, password);
		await this.auth.currentUser.updateProfile({ displayName: name });
		return await this.auth.currentUser.sendEmailVerification();
	}

	createList(id) {
		return this.db.collection("shopping-lists").doc(id);
	}

	getList(id) {
		return this.db.collection("shopping-lists").doc(id);
	}

	deleteList(id){
		return this.db.collection("shopping-lists").doc(id).delete();
	}

	setOnAuthStateChanged(stateChangedHandler) {
		return this.auth.onAuthStateChanged(stateChangedHandler);
	}

	getCurrentUser() {
		return this.auth.currentUser;
	}

	getMyLists() {
		return this.db.collection("shopping-lists").where("shared_with", "array-contains", this.auth.currentUser.uid);
	}
}

const firebaseAPI = new FirebaseAPI();

export default firebaseAPI;