import React from 'react'
import { useToasts } from 'react-toast-notifications'
import DeleteButton from '../DeleteButton';
import { useSession } from '../../App';
import { useNavigate } from 'react-router-dom';
import {LISTS} from '../../constants/routes';

function LeaveList(props) {
	const { addToast } = useToasts();
	const user = useSession();
	const navigate = useNavigate();
	
	const leaveHandler = () => {
		var shared_with = props.shared_with;

		const i = shared_with.indexOf(user.uid);
		if (i > -1) {
			shared_with.splice(i, 1);
		}

		props.listRef.set({ shared_with: shared_with }, { merge: true })
		.then(() => {
			addToast("You left the list!", { appearance: 'success', autoDismiss: true });
			navigate(LISTS);
		})
		.catch((e) => {
			 addToast("Unable to remove you from the list. Please try again a later.", { appearance: 'error', autoDismiss: true, });
		});
	};
	
	return (<>
		<h2>Leave list</h2>
		<p>To leave the list press and hold the leave button below.</p><br/>
		<DeleteButton buttonText="Leave list" holdButtonText="Hold to leave" deleteHandler={leaveHandler} />
    </>);
}

export default LeaveList