import React, { useState, useEffect }  from 'react';
import Item from '../../components/Item';
import NewItem from '../../components/NewItem';
import Name from '../../components/Name';
import Spinner from '../../components/Spinner';
import AppHeader from '../../components/ApptHeader'
import firebase from "../../components/Firebase";
import {LISTS} from '../../constants/routes';
import DeleteButton from '../../components/DeleteButton';
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { v4 as uuidv4 } from 'uuid';
import { DndContext, closestCenter, MouseSensor, TouchSensor, KeyboardSensor, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';
import confetti from 'canvas-confetti';


import './ListScreen.scss';

function ListScreen() {
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [items, setItems] = useState([]);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    let unsubscribe = null;
    let newDocRef = firebase.getList(window.location.pathname.replace('/list/', ''));
    newDocRef.get()
      .then(doc => {
        if (doc.exists) {
          unsubscribe = newDocRef.onSnapshot(doc => {
            let data = doc.data();

            // Add to shared with if not already in the list
            if(!data.shared_with.includes(firebase.getCurrentUser().uid)) {
              var shared_with = [...data.shared_with];
              shared_with.push(firebase.getCurrentUser().uid);
              saveList(data.name, data.items, shared_with);
              return;
            }

            if(data.formatVersion === 1) {
              data.items.map(item => ( item.id = uuidv4() ));
              saveList(data.name, data.items, shared_with);
            }

            setName(data.name);
            setItems(data.items);
            setIsLoading(false);
          });
        } else {
          setIsLoading(false);
          setError(true);
          setErrorMessage('Unknown list id');
        }
      })
      .catch(error => {
        setIsLoading(false);
        setError(true);
        setErrorMessage('Whoa! That\'s not suppose to happen.');
      });

      return () => {
        unsubscribe && unsubscribe();
      }
	}, []);

  const fireConfetti = () => {
    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }

  const fire = (particleRatio, opts) => {
    const count = 200;
    const defaults = { origin: { y: 0.7 } };

    confetti(Object.assign({}, defaults, opts, {
      particleCount: Math.floor(count * particleRatio)
    }));
  }

  const doneHandler = (event, id) => {
    event.preventDefault();
    let item = items.find(item => item.id === id);
    item.done = !item.done;
    saveList(name, items);
  }

   const saveHandler = (item, value) => {
    item.text = value;
    saveList(name, items);
   }

  const deleteHandler = (item) => {
    items.splice(items.indexOf(item), 1);
    saveList(name, items);
  }

  const addHandler = (value) => {
    items.push({text: value, id: uuidv4() });
    saveList(name, items);
  }

  const saveNameHandler = newName => {
    setName(newName);
    saveList(newName, items);
  }

  const saveList = (name, items, shared_with) => {
    let docRef = firebase.getList(window.location.pathname.replace('/list/', ''));

    if(shared_with) {
      docRef.set({ name: name, items: items, formatVersion: 2, shared_with: shared_with},{ merge: true })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    } else {
      docRef.set({ name: name, items: items, formatVersion: 2},{ merge: true })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    }
  }

  const removeDoneHandler = () => {
    saveList(name, items.filter(item => !item.done))
    fireConfetti();
  }

  const handleDragEnd = (event) => {
    const {active, over} = event;

    if (active.id !== over.id) {
      const activeIndex = items.indexOf(items.find(item => item.id === active.id))
      const overIndex = items.indexOf(items.find(item => item.id === over.id))

      let tempItems = arrayMove(items, activeIndex, overIndex);
      setItems(tempItems);
      saveList(name, tempItems);
    }
  }

  if (isLoading) {
    return (
      <Spinner/>
    );
  }

  if (error) {
    return (
      <div>
        {errorMessage}
      </div>
    );
  }

  return (<>
    <AppHeader backPath={LISTS} title={name}>
      <Name value={name} onSave={saveNameHandler} />
    </AppHeader>

    <div className="main-content-with-menu">
      <div className="list-container">
        <NewItem onSave={addHandler} />
          <DndContext 
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}>
              <SortableContext 
                items={items.filter(item => !item.done)} 
                strategy={verticalListSortingStrategy}>  
                <ul>
                  {items.filter(item => !item.done).map((item) => (
                    <Item key={item.id} value={item.text} handle={true} id={item.id} done={item.done} onDone={(event) => doneHandler(event, item.id)} onDelete={() => deleteHandler(item)} onSave={(value) => saveHandler(item, value)}/>
                  ))}
                </ul>
              </SortableContext>
          </DndContext>

          {items.filter(item => item.done).length > 0 &&
            <div className="done-list">
              {items.filter(item => !item.done).length > 0 &&
                <hr/>
              }
                <ul>
                  {items.filter(item => item.done).map((item) => (
                    <Item key={item.id} value={item.text} id={item.id} done={item.done} onDone={(event) => doneHandler(event, item.id)} onDelete={() => deleteHandler(item)} onSave={(value) => saveHandler(item, value)}/>
                  ))}
                </ul>
                <DeleteButton buttonText="Remove done items" holdButtonText="Hold to remove" deleteHandler={removeDoneHandler} />
            </div>
          }
      </div>
    </div>
    </>
  );
}

export default ListScreen;