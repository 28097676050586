import React, {useState} from 'react';
import './Name.scss';

function Name(props) {
  const [value, setValue] = useState(props.value);
  const [editMode, setEditMode] = useState(false);

  const handleKeyUp = e => {
    if (e.key === 'Enter') {
      saveName();
    }
  };

  const saveName = () => {

    if(value && value !== '') {
      props.onSave(value);
    } else {
       // revert back to original value if empty
       setValue(props.value);
    }  

    setEditMode(false);
  }

  if (editMode) {
    return (
      <input autoFocus type="text"
        value={value}
        onChange={e => setValue(e.target.value)}
        onKeyUp={handleKeyUp}
        onBlur={saveName}
        className="name-input-field" />
    );
  }

  return (
    <h2 onDoubleClick={e => setEditMode(true)}>{value}</h2>
  );
}

export default Name;