import React, { useState, useEffect } from 'react';
import firebase from '../../components/Firebase'
import Spinner from '../../components/Spinner';
import Button from '../../components/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {RESET_PASSWORD, LOGIN} from '../../constants/routes';

function SetPasswordPage(props) {
	const { addToast } = useToasts();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const [password, setPassword] = useState('');
	const [working, setWorking] = useState(false);

	useEffect(() => {
		firebase.verifyPasswordResetCode(props.oneTimeCode).then(() => {
			setLoading(false);
		}).catch(() => {
			addToast("The reset password link is invalid. This can happen if the link is malformed, expired, or has already been used. Please try again.", {
				appearance: 'error',
				autoDismiss: false,
			});
			setLoading(false);
			navigate(RESET_PASSWORD);
		});
	}, [props.oneTimeCode, addToast, navigate]);

	async function submintSetPassword(event) {
		event.preventDefault();

		setWorking(true);
		firebase.confirmPasswordReset(props.oneTimeCode, password)
			.then(() => {
				addToast("New password set. Please login", {
					appearance: 'success',
					autoDismiss: true,
					autoDismissTimeout: 8000
				});
				navigate(LOGIN);
			}).catch((error) => {
				addToast("Unable to set password. " + error.message, {
					appearance: 'error',
					autoDismiss: false,
				});
				setWorking(false);
			});
	}

	if (loading) {
		return <Spinner />;
	}

	return (
		<div className="narrow-page-container set-password-container">
			<h1>Set password</h1>

			<form>
				<input id="password" name="password" type="password" placeholder="Password" autoFocus autoComplete="off" value={password} onChange={e => setPassword(e.target.value)} />
				<Button className="submit" type="submit" clickCallback={submintSetPassword} buttonText="Save" working={working} />
			</form>

			<span className="secondary-link "><Link className="secondary" to={LOGIN}>Back to login</Link></span>
		</div>
	);
}

export default SetPasswordPage;