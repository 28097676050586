import React, { useState, useEffect } from 'react';
import firebase from '../Firebase'
import Spinner from '../Spinner';
import { Link, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useSession } from '../../App';
import {LISTS, LOGIN} from '../../constants/routes';

function VerifyEmailPage(props) {
	const { addToast } = useToasts();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const user = useSession();
	
	useEffect(() => {
		firebase.verifyEmail(props.oneTimeCode).then(() => {
			setLoading(false);
		}).catch((error) => {
			setLoading(false);
			addToast("Unable to verify email address! Details: " + error.message, {
				appearance: 'error',
				autoDismiss: false,
			});
			navigate('/');
		});
	}, [props.oneTimeCode, addToast, navigate]);

	if (loading) {
		return <Spinner />;
	}

	return (
		<div className="narrow-page-container set-password-container">
			<h1>Great success!</h1>

			<p>Your email is verified. Good times ;-)</p>

			<span className="secondary-link "><Link to={user ? LISTS : LOGIN}>{user ? 'Take me to my lists' : 'Login'}</Link></span>
		</div>
	);
}

export default VerifyEmailPage;