import React, { useEffect, useState } from 'react'
import AppHeader from '../../components/ApptHeader'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import firebase from '../../components/Firebase'
import Spinner from '../../components/Spinner';
import { useSession } from '../../App';
import './ListSettingsScreen.css';
import DeleteList from '../../components/DeleteList';
import LeaveList from '../../components/LeaveList';
import {LISTS, SETTINGS} from '../../constants/routes';

function ListSettingsScreen() {
	const { id } = useParams();
	const location = useLocation();
	const listURL = location.pathname.replace(SETTINGS, '');
	const [owner, setOwner] = useState('');
	const [sharedWith, setSharedWith] = useState([]);
	const [listRef, setListRef] = useState(null);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const user = useSession();
	const navigate = useNavigate();

	useEffect(() => {
		var unsubscribe = null;
		var docRef = firebase.getList(id);
		setListRef(docRef); 

		docRef.get()
			.then(doc => {
				if (doc.exists) {
					unsubscribe = docRef.onSnapshot(doc => {
						let data = doc.data();

						if(data) {
							setSharedWith(data.shared_with);
							setOwner(data.owner);
						}
					});

					setLoading(false);
				} else {
					// Unknown list. Must have been deleted.
					setError(true);
				}
			})
			.catch(error => {
				// Some random error. Send them back to list view. To tired to make toasts.
				setError(true);
			});

		return () => {
			unsubscribe && unsubscribe();
		};
	}, [id]);

	if (error) {
		navigate(LISTS);
	}

	if (loading) {
		return (
			<>
				<AppHeader backPath={listURL} title="Settings" hideSettingsButton={true} />
				<div className="main-content-with-menu">
					<Spinner />
				</div>
			</>
		);
	};

	const listActionComponent = owner === user.uid ? <DeleteList owner={owner} id={id}/> : <LeaveList shared_with={sharedWith} listRef={listRef}/>;

	return (<>
		<AppHeader backPath={listURL} title="Settings" hideSettingsButton={true} />
		<div className="narrow-page-container list-settings-page-container">
			<h2>Share list</h2>
			<p>To share this list with someone, send this url to them:<br/><br/></p><p className="share">{window.location.href.replace(SETTINGS, '')}</p><br/><br/>

			{listActionComponent}
		</div>
	</>
	);
}

export default ListSettingsScreen