import React from 'react'
import SetPasswordPage from '../../components/SetPasswordPage'
import VerifyEmailPage from '../../components/VerifyEmailPage';

function FirebaseEmailActionScreen() {
	const params = parseQueryString();
	const mode = params["mode"];
	const oneTimeCode = params["oobCode"];

	// From stack owerflow. Hope and commit.
	function parseQueryString() {
		var str = window.location.search;
		var objURL = {};
	
		str.replace(
			new RegExp( "([^?=&]+)(=([^&]*))?", "g" ),
			function( $0, $1, $2, $3 ){
				objURL[ $1 ] = $3;
			}
		);
		return objURL;
	};

	if (mode === 'resetPassword') {
		return <SetPasswordPage oneTimeCode={oneTimeCode}/>;
	} 

	if (mode === 'verifyEmail') {
		return <VerifyEmailPage oneTimeCode={oneTimeCode}/>
	}
	
	return (<p> Unknown action code in link. Please try again.</p>);
}

export default FirebaseEmailActionScreen